import contacts from './contacts.json';
import navItems from './nav-items.json';
import menuItemsBurger from './menuItemsBurger.json';
import menuItemsDrink from './menuItemsDrink.json';
import menuItemsLunch from './menuItemsLunch.json';
import menuItemsPizza from './menuItemsPizza.json';
import menuItemsRoll from './menuItemsRoll.json';
import menuItemsSetOfRolls from './menuItemsSet_of_rolls.json';
import menuItemsSnack from './menuItemsSnack.json';
import menuItemsWok from './menuItemsWok.json';
import menuItemsSalad from './menuItemsSalad.json';

const menuItems = {
  pizza: {
    name: 'Пицца',
    items: menuItemsPizza,
  },
  snaks: {
    name: 'Закуски',
    items: menuItemsSnack,
  },
  burgers: {
    name: 'Бургеры',
    items: menuItemsBurger,
  },
  salads: {
    name: 'Салаты',
    items: menuItemsSalad,
  },
  wok: {
    name: 'Лапша WOK',
    items: menuItemsWok,
  },
  // rolls: {
  //   name: 'Роллы',
  //   items: [...menuItemsSetOfRolls, ...menuItemsRoll],
  // },
  lanches: {
    name: 'Ланчи',
    items: menuItemsLunch,
  },
  drinks: {
    name: 'Напитки',
    items: menuItemsDrink,
  },
};

export { contacts, navItems, menuItems };
