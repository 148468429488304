// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/web/origins/pizza-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("/home/web/origins/pizza-site/src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-index-js": () => import("/home/web/origins/pizza-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-js": () => import("/home/web/origins/pizza-site/src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/web/origins/pizza-site/.cache/data.json")

