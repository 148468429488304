const SHOW_TOAST = 'SHOW_TOAST';
const HIDE_LAST_TOAST = 'HIDE_LAST_TOAST';
const UPDATE_BASKET = 'UPDATE_BASKET';

export const TOAST_TTL = 2200;
export const MAX_TOASTS_COUNT = 5;

const initialState = {
  toasts: [],
  basket: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };

    case HIDE_LAST_TOAST:
      return {
        ...state,
        toasts: state.toasts.slice(1),
      };

    case UPDATE_BASKET:
      return {
        ...state,
        basket: action.payload,
      };

    default:
      return state;
  }
};

// Action Creators
export const toastShown = payload => ({
  type: SHOW_TOAST,
  payload,
});

export const olderToastHidden = () => ({
  type: HIDE_LAST_TOAST,
});

export const basketUpdated = payload => ({
  type: UPDATE_BASKET,
  payload,
});

export const showToast = toastProps => (dispatch, getState) => {
  if (getState().app.toasts.length > MAX_TOASTS_COUNT) {
    dispatch(olderToastHidden());
  }

  return dispatch(toastShown(toastProps));
};

export const hideLastToast = () => dispatch => dispatch(olderToastHidden());

export const updateBasket = items => dispatch => dispatch(basketUpdated(items));
