import { menuItems } from '../../data';

const CHANGE_ACTIVE_CAT = 'CHANGE_ACTIVE_CAT';

const initialState = {
  activeCategory: Object.keys(menuItems)[0],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_ACTIVE_CAT:
      return {
        ...state,
        activeCategory: action.payload,
      };

    default:
      return state;
  }
};

const changeActiveCat = payload => ({
  type: CHANGE_ACTIVE_CAT,
  payload,
});

export const switchCategory = id => dispatch => dispatch(changeActiveCat(id));
